// import { FaCheckDouble } from "react-icons/fa6";
// import { HiArrowPathRoundedSquare } from "react-icons/hi2";
// import { HiDocumentDuplicate } from "react-icons/hi2";

import { Link } from "react-router-dom";

const Care = () => {
    const data = [
        // {
        //     id: 1,
        //     title: "Primary Care",
        //     // icon: <FaCheckDouble />,
        //     content:
        //         "As part of our commitment to accessible healthcare, our primary care services cover preventive care, chronic disease management, and general health consultations. Our dedicated providers build long-term relationships, focusing on holistic care plans and patient education to promote overall wellness.",
        // },
        {
            id: 1,
            title: "Laboratory Services",
            // icon: <FaCheckDouble />,
            content:
                "Comprehensive diagnostic laboratory tests and screenings, including blood tests, urine tests, imaging studies, and pathology services for accurate diagnosis and treatment planning.",
        },
        {
            id: 2,
            title: "Surgeon Services",
            // icon: <HiArrowPathRoundedSquare />,
            content:
                "Surgical interventions and procedures performed by experienced surgeons, including minimally invasive surgeries, orthopedic surgeries, general surgeries, and more.",
        },
        // {
        //     id: 2,
        //     title: "Maternity Care",
        //     // icon: <HiArrowPathRoundedSquare />,
        //     content:
        //         "Our clinic hospital provides comprehensive and compassionate maternity care, focusing on affordability and accessibility. From prenatal care to postnatal support, our experienced team ensures the health and well-being of both mother and child, empowering informed decisions throughout the pregnancy journey.",
        // },
        {
            id: 3,
            title: "Emergency Care",
            // icon: <HiDocumentDuplicate />,
            content:
                "With expert emergency medicine specialists and state-of-the-art facilities, we deliver timely interventions that save lives and restore health with quality and compassion.",
        },
    ];

    return (
        <section className="w-full flex flex-col items-center justify-center care_section">
            <div className="lg:w-10/12  flex lg:flex-row flex-col  lg:justify-between gap-4 justify-center lg:items-stretch items-center">
                {data.map((item) => (
                    <div key={item.id} className="card p-4 lg:w-4/12 w-11/12">
                        <div className="flex w-full items.center ">
                            <h1 className="font-semibold text-xl blue_text my-4">
                                {item.title}
                            </h1>
                        </div>
                        <p className="opacity-70 text-sm">{item.content}</p>
                    </div>
                ))}
            </div>
            <Link
                to="services"
                className="inline-block mt-8  text-white rounded-xl border border-white hover:bg-white hover:text-blue-900 px-8 py-1 ease-in-out duration-300 hover:shadow-xl"
            >
                See more of our services
            </Link>
        </section>
    );
};

export default Care;
