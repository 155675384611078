import React, { useEffect } from "react";

import Happy_patient from "../assets/images/happy_patient.jpg";

export const metadata = {
    title: "CCOPEM HOSPITAL & MATERNITY | CAREERS",
    description:
        "At CCOPEM HOSPITAL & MATERNITY, we are dedicated to offering exceptional service, advanced technology, and compassionate care to our patients.",
};

const Careers = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top (x: 0, y: 0)
    }, []); // Empty dependency array ensures it runs only on mount

    // ... your component content

    return (
        <section className="w-full flex flex-col items-center justify-center">
            <div className="w-full page_banner career_header flex items-center justify-center">
                <h1 className="lg:text-6xl text-5xl text-white font-bold">
                    Careers
                </h1>
            </div>
            <div className="lg:w-10/12 w-11/12 flex lg:flex-row flex-col lg:justify-between justify-center gap-4 mt-8 p-4">
                <div className="lg:w-5/12 w-full">
                    <h1 className="font-bold ">
                        Join Our Team: Make a Difference at CCOPEM Hospital &
                        Maternity
                    </h1>
                    <p className="my-2 opacity-70 text-sm">
                        CCOPEM Hospital & Maternity is more than just a
                        healthcare provider; we&apos;re a passionate team
                        dedicated to improving the lives of our patients and the
                        communities we serve in Lagos, Nigeria.
                    </p>
                    <span className="font-semibold mt-2 ">
                        Are you a talented and compassionate healthcare
                        professional looking to make a real impact? <br />
                    </span>
                    <p className="my-2 opacity-70 text-sm">
                        We&apos;re always searching for bright individuals to
                        join our growing team.
                    </p>
                    <h2 className="font-bold mt-4 mb-2 ">How to apply</h2>
                    <p className="opacity-70 text-sm">
                        We encourage you to submit your resume and cover letter
                        for any positions that align with your skills and
                        experience. Please email your application along with
                        relevant documents to ccopemhospital@gmail.com.
                    </p>
                </div>
                <div className="lg:w-6/12 w-full rounded-lg">
                    <img
                        className="w-full rounded-lg"
                        alt="happy patient"
                        src={Happy_patient}
                        lazy="true"
                    />
                </div>
            </div>
        </section>
    );
};

export default Careers;
