import Logo from "../assets/images/ccopem.png";
import { Link } from "react-router-dom";

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();
    return (
        <footer className="w-full border-t flex flex-col items-center justify-center text-sm">
            <Link
                to="/"
                className="flex flex-col items-center justify-center inline-block"
            >
                <img
                    src={Logo}
                    alt="logo"
                    width={100}
                    height={100}
                    className="my-8"
                    lazy="true"
                />
            </Link>
            <div className="flex lg:flex-row flex-col justify-center lg:justify-between lg:w-8/12 w-10/12 gap-8">
                <div className="lg:w-4/12 w-full text-center">
                    <h2 className="font-semibold mb-4">CCOPEM</h2>
                    <div className="underLine"></div>
                    <p className="text-xs opacity-70">
                        At CCOPEM HOSPITAL & MATERNITY, we are dedicated to
                        offering exceptional service, advanced technology, and
                        compassionate care to our patients.
                    </p>
                </div>

                <div className="lg:w-4/12 w-full text-center">
                    <h2 className="font-semibold mb-4">Contact Details</h2>
                    <div className="underLine"></div>
                    <p className="text-xs opacity-70 my-1">
                        4, Isa Bello Close ,Behind Eduland Schools, Bakery
                        B/Stop Akowonjo Road, Egbeda Lagos
                    </p>

                    <p className="text-xs opacity-70 my-1">
                        24/7 services. Mon-Sun
                    </p>
                </div>

                <div className="lg:w-4/12 w-full text-center">
                    <h2 className="font-semibold mb-4">Make an Appointment</h2>
                    <div className="underLine"></div>
                    <p className="text-xs opacity-70 my-1">
                        Feel free to call us
                    </p>
                    <p className="text-xs opacity-70 my-1">
                        enquires@ccopemhospital.com
                    </p>
                    <p className="text-xs opacity-70 my-1">+2348033083579 </p>
                </div>
            </div>
            <div className="mt-4 flex flex-col items-center justify-center  w-full py-2 footer_paragraph">
                <div className="flex lg:flex-row flex-col items-center lg:justify-between justify-center gap-2 w-10/12 py-4 text-white font-semibold">
                    <p className="text-xs opacity-70 my-1 ">
                        Christ City of Peace & Maternity Hospital (CCOPEM)
                    </p>
                    <p className="text-xs opacity-70 my-1">
                        Copyright © {year} christ city of peace Hospital
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
