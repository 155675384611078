import React, { useEffect } from "react";

export const metadata = {
    title: "CCOPEM HOSPITAL & MATERNITY | CONTACT US",
    description:
        "At CCOPEM HOSPITAL & MATERNITY, we are dedicated to offering exceptional service, advanced technology, and compassionate care to our patients.",
};

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top (x: 0, y: 0)
    }, []); // Empty dependency array ensures it runs only on mount

    // ... your component content

    return (
        <section className="w-full flex flex-col items-center justify-center">
            <div className="w-full page_banner contact_header flex items-center justify-center">
                <h1 className="lg:text-6xl text-5xl text-white font-bold text-center">
                    Reach Out To Us
                </h1>
            </div>
            <div className="lg:w-10/12 w-11/12 flex lg:flex-row flex-col lg:justify-between justify-center gap-4 mt-8 p-4">
                <div className="lg:w-5/12 w-full">
                    <h1 className="font-bold  text-3xl">
                        Contact CCOPEM Hospital & Maternity
                    </h1>
                    <p className="my-4 leading-loose text-sm opacity-70">
                        We&apos;re here to help! Whether you have a question,
                        need to schedule an appointment, or simply want to learn
                        more about our services, we encourage you to reach out
                        to us.
                    </p>
                </div>
                <div className="lg:w-6/12 w-full rounded-lg">
                    <div className=" w-full text-justify">
                        <form method="post">
                            <div className="flex flex-col gap-2 my-3">
                                <label htmlFor="name" className="font-semibold">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    required
                                    className="focus:outline-none focus:border-none active:outline-none active:border-none bg-gray-200 text-sm  py-2 px-8"
                                    placeholder="John Doe"
                                />
                            </div>
                            <div className="flex flex-col gap-2 my-3">
                                <label
                                    htmlFor="sender_email"
                                    className="font-semibold"
                                >
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="sender_email"
                                    name="sender_email"
                                    required
                                    className="focus:outline-none focus:border-none active:outline-none active:border-none bg-gray-200 text-sm  py-2 px-8"
                                    placeholder="email@example.com"
                                />
                            </div>
                            <div className="flex flex-col gap-2 my-3">
                                <label
                                    htmlFor="message"
                                    className="font-semibold"
                                >
                                    Message
                                </label>
                                <textarea
                                    type="text"
                                    id="message"
                                    name="message"
                                    required
                                    cols={8}
                                    rows={8}
                                    className="focus:outline-none focus:border-none active:outline-none active:border-none bg-gray-200 text-sm  py-2 px-8"
                                    placeholder="How can i help you?"
                                    style={{ resize: "none" }}
                                ></textarea>
                            </div>
                            <button className="submitBtn text-white w-5/12 flex items-center justify-center p-2">
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
