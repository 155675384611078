"use client";
import React, { useState } from "react";
import Logo from "../assets/images/ccopem.png";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
    const [mobile, setMobile] = useState(false);
    const handleMobile = () => {
        setMobile(!mobile);
    };

    const path = useLocation();
    const pathname = path.pathname;

    return (
        <nav className=" lg:w-10/12 w-full lg:px-1 px-4 flex items-center justify-between h-full relative">
            <Link to="/" className="flex flex-col items-center justify-center">
                <img
                    src={Logo}
                    alt="logo"
                    width={60}
                    height={60}
                    className="logo"
                    lazy="true"
                />
                <h1 className="text-sm font-bold content_blue_text lg:block hidden">
                    CCOPEM Hospital & Maternity
                </h1>
            </Link>
            <ul className="lg:flex hidden h-full text-sm">
                <Link
                    to="/"
                    className={`h-full flex items-center px-4 nav__link ${
                        pathname === "/" ? "text-blue-700" : null
                    } `}
                >
                    <li>Home</li>
                </Link>
                <Link
                    to="/about"
                    className={`h-full flex items-center px-4 nav__link ${
                        pathname === "/about" ? "text-blue-700" : null
                    } `}
                >
                    <li>About Us</li>
                </Link>

                <Link
                    to="/services"
                    className={`h-full flex items-center px-4 nav__link ${
                        pathname === "/services" ? "text-blue-700" : null
                    } `}
                >
                    <li>Services</li>
                </Link>

                <Link
                    to="/careers"
                    className={`h-full flex items-center px-4 nav__link ${
                        pathname === "/careers" ? "text-blue-700" : null
                    } `}
                >
                    <li>Careers</li>
                </Link>
                <Link
                    to="/contact"
                    className={`h-full flex items-center px-4 nav__link ${
                        pathname === "/contact" ? "text-blue-700" : null
                    } `}
                >
                    <li>Contact Us</li>
                </Link>
            </ul>

            {/* Mobile Menu */}

            <ul
                className={`flex lg:hidden h-full text-sm flex flex-col items-center mobile__menu ${
                    mobile ? "active" : "in-active"
                }`}
            >
                <Link
                    to="/"
                    onClick={handleMobile}
                    className={`py-4 w-full border-b flex items-center justify-center px-4 mobile__link ${
                        mobile ? "active" : "in-active"
                    }`}
                >
                    <li>Home</li>
                </Link>
                <Link
                    to="/about"
                    onClick={handleMobile}
                    className={`py-4 w-full border-b flex items-center justify-center px-4 mobile__link ${
                        mobile ? "active" : "in-active"
                    }`}
                >
                    <li>About Us</li>
                </Link>

                <Link
                    to="/services"
                    onClick={handleMobile}
                    className={`py-4 w-full border-b flex items-center justify-center px-4 mobile__link ${
                        mobile ? "active" : "in-active"
                    }`}
                >
                    <li>Services</li>
                </Link>

                <Link
                    to="/careers"
                    onClick={handleMobile}
                    className={`py-4 w-full border-b flex items-center justify-center px-4 mobile__link ${
                        mobile ? "active" : "in-active"
                    }`}
                >
                    <li>Careers</li>
                </Link>
                <Link
                    to="/contact"
                    onClick={handleMobile}
                    className={`py-4 w-full border-b flex items-center justify-center px-4 mobile__link ${
                        mobile ? "active" : "in-active"
                    }`}
                >
                    <li>Contact Us</li>
                </Link>
            </ul>

            <div
                className={`menu h-full flex items-center justify-center lg:hidden block`}
                onClick={handleMobile}
            >
                <div
                    className={`menu__btn ${mobile ? "active" : "in-active"}`}
                ></div>
            </div>
        </nav>
    );
};

export default Navigation;
