import { Link } from "react-router-dom";

import DoctorImg from "../assets/images/doctor1.jpg";

const HealthArticle = () => {
    return (
        <section className="w-full flex flex-col items-center justify-center my-8">
            <div className="flex lg:flex-row flex-col gap-8 justify-between items-center lg:w-10/12">
                <div className="lg:w-5/12 w-10/12 relative">
                    <img
                        src={DoctorImg}
                        alt="doctor visit"
                        className="w-full doc_article_img"
                    />
                </div>
                <div className="p-4 lg:w-6/12 w-11/12">
                    <h1 className="font-semibold text-xl lg:mb-8 mb-4">
                        Prioritize Your Health: A Visit To a Doctor Can Make a
                        Difference
                    </h1>
                    <p className="opacity-70 text-sm lg:text-left text-justify">
                        Feeling under the weather? Don&apos;t wait until it
                        worsens! Early detection and treatment are key to
                        maintaining good health. Here at CCOPEM Hospital, our
                        dedicated team is here to listen to your concerns and
                        provide the care you need. Whether it&apos;s a routine
                        checkup, managing an existing condition, or addressing a
                        sudden illness, a visit with our primary care doctors
                        can make all the difference. We offer comprehensive
                        services to keep you healthy at every stage. Don&apos;t
                        delay, prioritize your well-being. Call us today to
                        schedule an appointment!
                    </p>
                    <Link
                        to="https://wa.me/+2348033083579"
                        className="mt-4 text-sm px-8 py-2 rounded inline-block call_to_action_btn"
                    >
                        Talk to a Doctor
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default HealthArticle;
