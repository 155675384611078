import React, { useEffect } from "react";
import Dental from "../assets/images/dental.jpg";
import Nutrition from "../assets/images/nutrition.jpg";
import Fertility from "../assets/images/fertility.jpg";
import LazyLoad from "react-lazyload";

export const metadata = {
    title: "CCOPEM HOSPITAL & MATERNITY | ABOUT CCOPEM",
    description:
        "At CCOPEM HOSPITAL & MATERNITY, we are dedicated to offering exceptional service, advanced technology, and compassionate care to our patients.",
};

const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top (x: 0, y: 0)
    }, []); // Empty dependency array ensures it runs only on mount

    // ... your component content

    return (
        <section className="w-full flex flex-col items-center justify-center">
            <div className="w-full page_banner about_header flex items-center justify-center">
                <h1 className="lg:text-6xl text-5xl text-white font-bold">
                    Our Services
                </h1>
            </div>
            <div className="lg:p-8 px-2 about_content flex flex-col lg:justify-between justify-center w-11/12 gap-4 m-8">
                <div className="service_one flex lg:flex-row flex-col gap-8 justify-center">
                    <div className="services flex flex-col items-center lg:w-6/12 w-full">
                        <h1 className="font-semibold">Primary Care</h1>
                        <p className="opacity-70 text-sm">
                            As part of our commitment to accessible healthcare,
                            our primary care services cover preventive care,
                            chronic disease management, and general health
                            consultations. Our dedicated providers build
                            long-term relationships, focusing on holistic care
                            plans and patient education to promote overall
                            wellness.
                        </p>
                        <h1 className="font-semibold mt-2">Orthopedic Care</h1>
                        <p className="opacity-70 text-sm">
                            Diagnosis and treatment of musculoskeletal
                            conditions, including fractures, joint injuries,
                            arthritis, sports injuries, and spine disorders,
                            with a focus on restoring mobility and function.
                        </p>

                        <h1 className="font-semibold mt-2">
                            Mental Health Services
                        </h1>
                        <p className="opacity-70 text-sm">
                            Counseling, therapy, and psychiatric consultations
                            for mental health disorders such as anxiety,
                            depression, PTSD, and mood disorders, promoting
                            emotional well-being and mental resilience.
                        </p>

                        <h1 className="font-semibold mt-2">Dental Care</h1>
                        <p className="opacity-70 text-sm">
                            Comprehensive dental services including routine
                            check-ups, cleanings, fillings, extractions, and
                            cosmetic dentistry procedures for a healthy and
                            beautiful smile
                        </p>
                    </div>
                    <LazyLoad
                        height={500}
                        once
                        className="lg:w-6/12 w-full lg:order-1 order-2 rounded-xl"
                    >
                        <img src={Dental} alt="dental" className="rounded-xl" />
                    </LazyLoad>
                </div>

                <div className="service_one flex lg:flex-row flex-col gap-8 justify-center mt-8">
                    <div className="services flex flex-col items-center lg:w-6/12 w-full lg:order-2 order-1">
                        <h1 className="font-semibold">Physical Therapy</h1>
                        <p className="opacity-70 text-sm">
                            Rehabilitation programs, exercise plans, and
                            therapeutic interventions for patients recovering
                            from injuries, surgeries, or chronic pain
                            conditions, improving mobility and functional
                            abilities.
                        </p>

                        <h1 className="font-semibold mt-2">
                            Women's Health Services
                        </h1>
                        <p className="opacity-70 text-sm">
                            Comprehensive healthcare services for women,
                            including gynecological exams, family planning,
                            menopause management, breast health screenings, and
                            fertility consultations.
                        </p>

                        <h1 className="font-semibold mt-2">
                            Nutrition Counseling
                        </h1>
                        <p className="opacity-70 text-sm">
                            Personalized nutrition plans, dietary assessments,
                            and counseling sessions with registered dietitians
                            to promote healthy eating habits and manage
                            nutrition-related health conditions.
                        </p>
                    </div>
                    <LazyLoad
                        height={500}
                        once
                        className="lg:w-6/12 w-full lg:order-1 order-2 rounded-xl"
                    >
                        <img
                            src={Nutrition}
                            alt="dental"
                            className="rounded-xl"
                        />
                    </LazyLoad>
                </div>
                <div className="service_one flex lg:flex-row flex-col gap-8 justify-center mt-8">
                    <div className="services flex flex-col items-center lg:w-6/12 w-full">
                        <h1 className="font-semibold">Geriatric Care</h1>
                        <p className="opacity-70 text-sm">
                            Comprehensive healthcare services tailored to the
                            needs of older adults, including geriatric
                            assessments, medication management, fall prevention
                            programs, memory screenings, and geriatric
                            consultations for age-related health concerns.
                        </p>

                        <h1 className="font-semibold mt-2">
                            Chronic Disease Management
                        </h1>
                        <p className="opacity-70 text-sm">
                            Individualized care plans and ongoing support for
                            patients with chronic health conditions such as
                            diabetes, hypertension, asthma, COPD, arthritis, and
                            heart disease, focusing on disease management,
                            lifestyle modifications, and prevention of
                            complications.
                        </p>

                        <h1 className="font-semibold mt-2">
                            Reproductive Health Services
                        </h1>
                        <p className="opacity-70 text-sm">
                            Reproductive health screenings, contraception
                            counseling, sexually transmitted infection (STI)
                            testing and treatment, preconception counseling, and
                            fertility evaluations for individuals and couples.
                        </p>
                    </div>
                    <LazyLoad
                        height={500}
                        once
                        className="lg:w-6/12 w-full lg:order-1 order-2 rounded-xl"
                    >
                        <img
                            src={Fertility}
                            alt="dental"
                            className="rounded-xl"
                        />
                    </LazyLoad>
                </div>
            </div>
        </section>
    );
};

export default Services;
