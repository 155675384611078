import React, { useEffect } from "react";

import Doctor from "../assets/images/doctor2_portrait.jpg";

export const metadata = {
    title: "CCOPEM HOSPITAL & MATERNITY | ABOUT CCOPEM",
    description:
        "At CCOPEM HOSPITAL & MATERNITY, we are dedicated to offering exceptional service, advanced technology, and compassionate care to our patients.",
};

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top (x: 0, y: 0)
    }, []); // Empty dependency array ensures it runs only on mount

    // ... your component content

    return (
        <section className="w-full flex flex-col items-center justify-center">
            <div className="w-full page_banner about_header flex items-center justify-center">
                <h1 className="lg:text-6xl text-5xl text-white font-bold">
                    About CCOPEM
                </h1>
            </div>
            <div className="lg:p-8 px-2 about_content flex lg:flex-row flex-col justify-between w-11/12 gap-4 m-8">
                <div className="lg:w-5/12 w-full">
                    <h2 className="font-bold text-2xl mb-4">A Brief History</h2>
                    <p className="mb-4 opacity-70 text-sm text-justify">
                        In the year 2003, a group of passionate healthcare
                        professionals came together with a shared vision of
                        creating a healthcare institution that would
                        revolutionize the way healthcare services were
                        delivered. With a strong commitment to providing
                        affordable healthcare services for individuals of all
                        ages, they founded our clinic hospital under the
                        registration number BN 221094. From its humble
                        beginnings, the clinic hospital quickly gained
                        recognition for its unwavering dedication to
                        accessibility and quality care.
                    </p>
                    <p className="mb-4 opacity-70 text-sm text-justify">
                        It became a beacon of hope for the community, offering
                        comprehensive medical services that catered to the needs
                        of both the young and the old. Over the years, our
                        clinic hospital has continuously evolved, staying true
                        to its mission of promoting wellness, preventing
                        disease, and improving the overall quality of life for
                        patients and their families.
                    </p>

                    <p className="mb-4 opacity-70 text-sm text-justify">
                        Through innovative healthcare initiatives and
                        partnerships, we have expanded our reach and impact,
                        ensuring that affordable healthcare remains a
                        fundamental right for everyone. In recognition of our
                        commitment to excellence, our clinic hospital is duly
                        registered with license number HF_2023_121_2218,
                        symbolizing our adherence to the highest standards of
                        healthcare delivery. As we look towards the future, our
                        vision remains steadfast - to be the leading provider of
                        affordable, accessible, and high-quality healthcare
                        services, making a positive difference in the lives of
                        individuals and communities we serve.
                    </p>
                </div>
                <div className="lg:w-5/12 w-full">
                    <img
                        alt="doctor"
                        src={Doctor}
                        className="w-full"
                        lazy="true"
                    />
                </div>
            </div>

            <div className="lg:w-9/12 w-10/12 flex lg:flex-row flex-col lg:gap-16 gap-8 justify-between mb-8">
                <div className="lg:w-6/12 w-full ">
                    <div>
                        <h2 className="font-bold text-4xl text-center lg:text-left">
                            Our Mission
                        </h2>
                        <p className=" opacity-70 text-sm mt-2">
                            To provide accessible, affordable, and high-quality
                            healthcare services to individuals of all ages, with
                            a focus on promoting wellness, preventing disease,
                            and enhancing the overall quality of life for our
                            patients and their families.
                        </p>
                    </div>
                    <div className="my-4">
                        <h2 className="font-bold text-4xl text-center lg:text-left">
                            Our Vision
                        </h2>
                        <p className=" opacity-70 text-sm mt-2">
                            To be the leading clinic hospital in our community,
                            known for our commitment to affordability without
                            compromising on the quality of care. We aspire to
                            create a healthier society by offering comprehensive
                            healthcare services that are accessible to everyone,
                            regardless of age or socioeconomic status. Our
                            vision encompasses a future where healthcare is a
                            fundamental right, and our clinic hospital serves as
                            a beacon of hope and healing for all.
                        </p>
                    </div>
                </div>
                <div className="lg:w-6/12 w-full ">
                    <h2 className="font-bold text-4xl text-center lg:text-left ">
                        Core Value
                    </h2>

                    <ul className=" opacity-70 text-sm mt-2 value_list">
                        <p className="mb-2">
                            <span className="font-bold opacity-100">
                                Accessibility:
                            </span>{" "}
                            We believe that healthcare should be accessible to
                            everyone, regardless of age or socioeconomic status.
                            We strive to eliminate barriers to healthcare access
                            and ensure that our services are readily available
                            to all members of the community.
                        </p>
                        <p className="mb-2">
                            <span className="font-bold opacity-100">
                                Affordability:
                            </span>{" "}
                            We are committed to providing affordable healthcare
                            services without compromising on quality. We believe
                            that cost should not be a barrier to receiving
                            necessary medical care, and we work tirelessly to
                            keep our services affordable for individuals and
                            families.{" "}
                        </p>
                        <p className="mb-2">
                            <span className="font-bold opacity-100">
                                Quality:
                            </span>{" "}
                            We maintain a steadfast commitment to delivering
                            high-quality healthcare services. Our team of
                            dedicated professionals adheres to rigorous
                            standards and best practices to ensure that every
                            patient receives safe, effective, and compassionate
                            care.
                        </p>
                        <p className="mb-2">
                            <span className="font-bold opacity-100">
                                Wellness Promotion:
                            </span>{" "}
                            We are dedicated to promoting wellness and disease
                            prevention. We believe in taking a proactive
                            approach to healthcare, empowering individuals to
                            adopt healthy lifestyles and make informed decisions
                            about their well-being.
                        </p>
                        <p className="mb-2">
                            <span className="font-bold opacity-100">
                                Community Impact:
                            </span>{" "}
                            We strive to make a positive impact on the community
                            we serve. Through community outreach programs,
                            education initiatives, and partnerships with local
                            organizations, we aim to address healthcare
                            disparities, improve health outcomes, and contribute
                            to the overall well-being of our community.
                        </p>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default About;
