import React, { useEffect } from "react";
import Hero from "./Hero";
import Care from "./Care";
import HealthArticle from "./HealthArticle";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top (x: 0, y: 0)
    }, []); // Empty dependency array ensures it runs only on mount

    // ... your component content

    return (
        <section>
            <Hero />
            <Care />
            <HealthArticle />
        </section>
    );
};

export default Home;
