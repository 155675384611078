import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Careers from "./components/careers";
import Contact from "./components/contact";
import About from "./components/about";
import Services from "./components/Services";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

function App() {
    return (
        <BrowserRouter>
            <main className="w-full flex flex-col items-center justify-center">
                <header className="w-full flex flex-col items-center justify-center sticky top-0">
                    <Navigation />
                </header>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/about" element={<About />} />
                    <Route exact path="/services" element={<Services />} />
                    <Route exact path="/careers" element={<Careers />} />
                    <Route exact path="/contact" element={<Contact />} />
                </Routes>
                <Footer />
            </main>
        </BrowserRouter>
    );
}

export default App;
