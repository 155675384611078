const Hero = () => {
    return (
        <section className="w-full flex flex-col items-center justify-center hero">
            <div className="lg:w-9/12 w-11/12">
                <div className="lg:w-6/12 w-11/12 flex flex-col hero_head">
                    <p className="blue_text_light font-bold text-xl">
                        Welcome to CCOPEM
                    </p>
                    <h1 className="text-6xl text-white font-bold">
                        We are here for your Care
                    </h1>
                    <p className="text-white text-sm my-4 opacity-80 hero_intro_p">
                        We are dedicated to offering exceptional service,
                        advanced technology, and compassionate care to our
                        patients.
                    </p>
                </div>
                <div className="lg:block hidden"></div>
            </div>
        </section>
    );
};

export default Hero;
